<script>
import axios from 'axios'
import OnboardingCard from '../../OnboardingCard.vue'
import OnboardingHeader from '../../OnboardingHeader.vue'
import OnboardingHeading from '../../OnboardingHeading.vue'

export default {
  name: 'ResetPassword',

  data() {
    return {
      confirmPassword: '',
      email: '',
      errors: [],
      isFormValid: false,
      loading: false,
      password: '',
      passwordResetSuccess: false,
      showConfirmPassword: false,
      showPassword: false,
      rules: {
        confirmPassword: value => this.password === value || 'Passwords do not match',
        password: value => {
          const passwordPattern = /[^A-Za-z0-9]/
          return (passwordPattern.test(value) && value.length > 7) || 'Must contain at least 8 characters and at least 1 special character'
        },
        required: value => !!value || 'This Field is required',
      },
      token: '',
    }
  },

  mounted() {
    this.getQueryParams()
  },

  components: {
    OnboardingCard,
    OnboardingHeader,
    OnboardingHeading
  },

  methods: {
    getQueryParams() {
      const url = new URL(window.location)
      url.searchParams.sort()
      const token = url.search.split('&token=')
      this.token = token[1]
      this.email = decodeURIComponent(token[0].split('?email=')[1])
    },

    async resetPasswordHandler() {
      try {
        this.loading = true
        const response = await axios.post('reset-password', {
          email: this.email,
          password_confirmation: this.confirmPassword,
          password: this.password,
          token: this.token,
        })

        const { success, errors } = response.data

        if(success) {
          this.passwordResetSuccess = true
          this.loading = false
        }

        if(!success) {
          this.errors = []
          this.errors.push(errors[0])
        }

      } catch (error) {
        this.errors = []
        this.errors.push(error.data.message)
        this.loading = false
      }
    }
  }
}
</script>

<template>
  <section class="onboarding v2">
    <OnboardingHeader />
    <OnboardingHeading heading="WooCommerce: Reset my password" />
    <p class="onboarding-text m-t-2" v-if="!passwordResetSuccess">
      Enter your new password below
    </p>

    <v-alert v-for="(value, key) in errors" v-bind:key="key" class="error rounded text-capitalize" :class="{ 'm-t-4': errors }" transition="fade-transition" border="left" style="width: 650px; margin-left: auto; margin-right: auto;" dismissible>
      <svg class="mr-5" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.99996 0.666626C4.39996 0.666626 0.666626 4.39996 0.666626 8.99996C0.666626 13.6 4.39996 17.3333 8.99996 17.3333C13.6 17.3333 17.3333 13.6 17.3333 8.99996C17.3333 4.39996 13.6 0.666626 8.99996 0.666626ZM8.99996 9.83329C8.54163 9.83329 8.16663 9.45829 8.16663 8.99996V5.66663C8.16663 5.20829 8.54163 4.83329 8.99996 4.83329C9.45829 4.83329 9.83329 5.20829 9.83329 5.66663V8.99996C9.83329 9.45829 9.45829 9.83329 8.99996 9.83329ZM9.83329 13.1666H8.16663V11.5H9.83329V13.1666Z" fill="#FF0000"/>
      </svg>
      <span>{{ value }}</span>
    </v-alert>

    <template v-if="!passwordResetSuccess">
      <OnboardingCard>
        <v-form v-model="isFormValid">
          <v-text-field
            :rules="[rules.required]"
            autocomplete="username"
            class="onboarding-input"
            dense
            disabled
            label="Email address"
            outlined
            type="email"
            v-model="email" />

          <v-text-field
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.password]"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            autocomplete="new-password"
            class="onboarding-input password m-y-1"
            dense
            hint="Must contain at least 8 characters and at least 1 special character"
            label="Password"
            outlined
            persistent-hint
            v-model="password" />

          <v-text-field
            :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.confirmPassword]"
            :type="showConfirmPassword ? 'text' : 'password'"
            @click:append="showConfirmPassword = !showConfirmPassword"
            autocomplete="new-password"
            class="onboarding-input password m-t-3 m-b-2"
            dense
            label="Password"
            outlined
            :disabled="password === ''"
            v-model="confirmPassword" />

          <v-btn
            :disabled="!isFormValid || (password !== confirmPassword)"
            :loading="loading"
            @click="resetPasswordHandler()"
            block
            class="onboarding-btn m-t-2"
            color="primary"
            elevation="0"
            rounded>
            Reset Password
          </v-btn>
        </v-form>
      </OnboardingCard>

      <p class="onboarding-text">
        If you still need help, contact <a href="mailto:support@syncio.co" class="btn-link">support@syncio.co</a>
      </p>
    </template>

    <OnboardingCard v-else>
      <aside class="onboarding-success-message">
        <p class="onboarding-text text-center m-y-0">
          Password reset success
        </p>
      </aside>

      <router-link to="/login" tag="div">
        <v-btn
          rounded
          class="onboarding-btn m-t-5"
          block
          color="primary"
          elevation="0">
          Login to Syncio
        </v-btn>
      </router-link>
    </OnboardingCard>
  </section>
</template>
